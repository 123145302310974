$(document).ready(function() {
  "use strict";

  // -----------
  // Mobile menu
  // -----------

  var handleMatchMedia = function(mediaQuery) {
      // ≤ 1023px
      if (mediaQuery.matches) {
        // crear el botó del menú
        // $('.main-nav').prepend('<button class="hamburger hamburger--3dx menu-button" aria-expanded="false" aria-label="Menú" id="nav"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="menu-button__label vh">Menú</span></button>')
        // ocultar el menú
        $(".menu-wrapper")
          .attr("hidden", "")
          .addClass("js-hidden");
        // clicar el botó
        $(".main-nav button").on("click", function() {
          // afegir classe al botó
          $(this).toggleClass("is-active");
          // afegir classe al body
          $("body").toggleClass("js-menu-open");
          // canviar atributs del botó i del menú
          if ($(this).attr("aria-expanded") == "true") {
            $(this).attr("aria-expanded", "false");
            $(".menu-wrapper")
              .attr("hidden", "")
              .addClass("js-hidden");
          } else {
            $(this).attr("aria-expanded", "true");
            $(".menu-wrapper")
              .removeAttr("hidden")
              .removeClass("js-hidden");
          }
        });
      }
      // ≥ 1024px
      else {
        // treure el botó
        // $('.main-nav button').remove();
        // mostrar el menú
        $(".menu-wrapper")
          .removeAttr("hidden")
          .removeClass("js-hidden");
      }
    },
    mq = window.matchMedia("only screen and (max-width: 1023px)");
  handleMatchMedia(mq);
  mq.addListener(handleMatchMedia);

  // --------
  // Submenús
  // --------

  // http://heydonworks.com/practical_aria_examples/#submenus
  $(".main-nav ul ul")
    .prev("a")
    .attr("aria-haspopup", "true")
    .append('<span aria-hidden="true"> &#x25be;</span>');

  var showSubmenu = function(dropdown) {
    dropdown.attr("aria-hidden", "false");
  };

  var hideSubmenu = function(dropdown) {
    dropdown.attr("aria-hidden", "true");
  };

  $(".with-dropdowns > li > a").on("focus", function(e) {
    hideSubmenu($('[aria-label="submenu"]'));
  });

  $("[aria-haspopup]").on("click", function(e) {
    var submenu = $(this).next();
    showSubmenu(submenu);
    //$(submenu).find('li:first-child a').focus();
    return false;
  });

  $("[aria-haspopup]").hover(function() {
    showSubmenu($(this).next());
    $(this).off("click");
  });

  $("[aria-haspopup]")
    .parents("li")
    .mouseleave(function() {
      hideSubmenu($(this).find('[aria-label="submenu"]'));
    });

  // ----------
  // feature.js
  // ----------

  if (feature.svg) {
    $("html").addClass("svg");
  }

  //---------
  // acordió
  //---------
  $(document).foundation({
    accordion: {
      callback: function(accordion) {
        console.log(accordion);
      }
    }
  });

  // -----
  // Login
  // -----

  $("a[href='#login-popup']").magnificPopup({
    type: "inline",
    closeMarkup:
      '<button title="%title%" type="button" class="mfp-close"></button>',
    midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
  });

  // -----
  // LoginError
  // -----
  if (location.hash == "#loginError") {
    $("a[href='#login-popup']").trigger("click");
    $("#formlogin legend").text(
      "There has been an error when trying to access, please try again."
    );
  }

  // -----
  // LostPassword
  // -----
  if (location.hash == "#lostpasswordOk") {
    $("a[href='#login-popup']").trigger("click");
    $("#formcont legend").text(
      "We have sent you an email with your new password."
    );
  }
  if (location.hash == "#lostpasswordError") {
    $("a[href='#login-popup']").trigger("click");
    $("#formcont legend").text("There was an error, please try again.");
  }

  //-------------
  // Popup
  // -------------

  $(".recover a").on("click", function() {
    $("#formcont").removeClass("js-hidden");
    return false;
  });

  // --------------------------------------------------------------------------------------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // --------------------------------------------------------------------------------------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function(event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function() {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // -----------------
  // Canvi svg per png
  // -----------------

  if (!feature.svg) {
    var imgs = document.getElementsByTagName("img");
    var endsWithDotSvg = /.*\.svg$/;
    var i = 0;
    var l = imgs.length;
    for (; i !== l; ++i) {
      if (imgs[i].src.match(endsWithDotSvg)) {
        imgs[i].src = imgs[i].src.slice(0, -3) + "png";
      }
    }
  }

  // fixed topbar
  if (window.matchMedia("(min-width: 64em)").matches) {
    $(".main-header-sub").scrollToFixed();
  }

  // -----------------
  // Smooth scroll top
  // -----------------

  if (window.matchMedia("(min-width: 64em)").matches) {
    $(window).scroll(function() {
      if ($(this).scrollTop() > 400) {
        $(".top").fadeIn();
      } else {
        $(".top").fadeOut();
      }
    });
    // var headerHeight = 82; // fixed header height
    $(function() {
      $("a[name]").addClass("offset");
      $(
        'a[href*="#"]:not([href="#"],[href="#menu"],[href="#login-popup"],[href^="#panel"])'
      ).click(function() {
        if (
          location.pathname.replace(/^\//, "") ==
            this.pathname.replace(/^\//, "") &&
          location.hostname == this.hostname
        ) {
          var target = $(this.hash);
          target = target.length
            ? target
            : $("[name=" + this.hash.slice(1) + "]");
          if (target.length) {
            // if (window.matchMedia("(min-width: 64.0625em)").matches) {
            //       $('html, body').animate({
            //         scrollTop: target.offset().top - headerHeight
            //       }, 1000);
            // } else {
            //       $('html, body').animate({
            //         scrollTop: target.offset().top
            //       }, 1000);
            // }
            $("html, body").animate(
              {
                scrollTop: target.offset().top
              },
              1000
            );
            return false;
          }
        }
      });
    });
  }

  // -------------
  // Sticky header
  // -------------

  /*var handleMatchMedia2 = function (mediaQuery) {
    // ≥ 1024px
    if (mediaQuery.matches) {
      var lastScrollTop = 115;
      $(window).scroll(function(event){
          var st = $(this).scrollTop();
          if (st > lastScrollTop){
              $('.main-header-sub').addClass('sticky');
              $('body').addClass('sticky-header');
          } else {
              $('.main-header-sub').removeClass('sticky');
              $('body').removeClass('sticky-header');
          }
          // lastScrollTop = st;
      });
    }
  },
  mq2 = window.matchMedia('only screen and (min-width: 1024px)');
  handleMatchMedia2(mq2);
  mq2.addListener(handleMatchMedia2);*/

  // ------------
  // Match height
  // ------------

  $(".box").matchHeight();
  $(".news-item").matchHeight();
  $(".agenda-item h2").matchHeight();
  $(".info-box").matchHeight();

  // --------------
  // Toggle idiomes
  // --------------

  $(".lang-toggle").click(function(event) {
    event.stopPropagation();
    $(this).toggleClass("close");
    $(this)
      .next()
      .toggleClass("is-hidden");
  });
  $(document).click(function() {
    $(".lang-toggle")
      .next()
      .addClass("is-hidden");
  });

  // ---------------------
  // Toggle eines virtuals
  // ---------------------

  $(".eines-toggle").click(function(event) {
    event.stopPropagation();
    $(this).toggleClass("close");
    $(this)
      .next()
      .toggleClass("is-hidden");
  });
  $(document).click(function() {
    $(".eines-toggle")
      .next()
      .addClass("is-hidden");
  });

  // -------------
  // Header search
  // -------------
  // (http://adrianroselli.com/2015/12/responsive-progressive-accessible-vanilla-search.html)

  // Get the search field by ID
  var eID = document.getElementById("s1");

  // Declare the event object and set it to null
  var eventObj = null;

  // Add the event listeners I want to track
  if (eID) {
    eID.addEventListener("focus", checkSearch, false);
    eID.addEventListener("blur", checkSearch, false);
    eID.addEventListener("keydown", checkSearch, false);
  }
  // per ie11
  $("label[for='s1']").on("click", function(e) {
    checkSearch(e);
    return false;
  });

  function checkSearch(eventObj) {
    try {
      // Get the event type from the object
      var eventType = eventObj.type;
      // Map the tab key to a variable
      var TABKEY = 9;
      // Get the search input text value
      var eIDval = eID.value;
      // Get the parent element
      var eIDparent = eID.parentElement;
      // Set a class since we are on it
      eIDparent.className = "notempty";
      // Get the button
      var eIDbutton = eIDparent.getElementsByTagName("button")[0];
      // Set the button tabindex
      eIDbutton.setAttribute("tabindex", "0");
      // If the text value is not blank
      if (eIDval != "") {
        // Give it a class that will stay
        eIDparent.className = "notempty";
      }
      // If the text value is blank
      else {
        // Check if focus has left the input first
        if (eventType == "blur") {
          // Set tabindex on button to remove from flow
          eIDbutton.setAttribute("tabindex", "-1");
          // Remove the class
          eIDparent.className = "";
        }
        // Check if user hit a key
        if (eventType == "keydown") {
          // See if the user hit the tab key
          if (eventObj.keyCode == TABKEY) {
            // Set tabindex on button to remove from flow
            eIDbutton.setAttribute("tabindex", "-1");
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  $(document).click(function() {
    $("[role=search]").removeClass("notempty");
  });

  // -------
  // Sliders
  // -------

  $(".slider").slick({
    dots: true,
    autoplay: false,
    arrows: true,
    autoplaySpeed: 12000
  });
  if ( typeof tempsSlider !== 'undefined') {
    
    $(".sliderPortada").slick({
      dots: false,
      autoplay: true,
      arrows: true,
      autoplaySpeed: tempsSlider
    });
  }

  $(".slider-news").slick({
    // dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });
});
